export enum RouteLinks {
  HOME = "/",

  // Okta Links
  OKTA_ISSUER_DEFAULT = "/oauth2/default",

  // Admin Links
  ADMIN = "/admin",
  ADMIN_SIGNUP = "/admin/signup",
  ADMIN_LOGIN = "/admin/login",
  ADMIN_LOGIN_CALLBACK = "/admin/login/callback",
  ADMIN_HOME = "/admin/home",
  ADMIN_PERSONAL = "/admin/personal",
  ADMIN_EDUCATIONAL = "/admin/educational",
  ADMIN_PROFESSIONAL = "/admin/professional",
  ADMIN_ACHIEVEMENTS = "/admin/achievements",
  ADMIN_HOBBIES = "/admin/hobbies",
}
