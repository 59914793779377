import React, { useState } from "react";

const OktaSignup: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSignup = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await fetch(`https://dev-76718137.okta.com/v1/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `SSWS ${process.env.REACT_APP_OKTA_API_TOKEN}`, // Get API token from Okta
        },
        body: JSON.stringify({
          profile: { firstName: name, email },
          credentials: { password: { value: password } },
        }),
      });

      if (!response.ok) throw new Error("Signup failed");
      setSuccess(
        "Signup successful! Please check your email to activate your account."
      );
    } catch (err) {
      setError(
        "Signup error: " +
          (err instanceof Error ? err.message : "Unknown error")
      );
    }
  };

  return (
    <div>
      <h2>Sign Up</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <form onSubmit={handleSignup}>
        <input
          type="text"
          placeholder="Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
};

export default OktaSignup;
