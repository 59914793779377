import React, { useEffect } from "react";
import Preloader from "../components/Preloader";
import Header from "../components/Header";
import About from "../components/About";
import Resume from "../components/Resume";
import Blog from "../components/Blog";
import Services from "../components/Services";
import Testimonial from "../components/Testimonial";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import BackToTop from "../components/BackToTop";

import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
import "swiper/swiper-bundle.min.css";
import "../assets/css/style.css";
import "swiper/swiper-bundle.css";
import CvBuilderService from "../service/CvBuilderService";

const HomePage: React.FC = () => {
  useEffect(() => {
    // AOS
    AOS.init({ duration: 2000 });
    const healthCheck = async () => {
      await CvBuilderService.healthCheck().then((response) => {
        console.log("Health check response:: " + JSON.stringify(response.data));
      });
    };

    const interval = setInterval(healthCheck, 60000);

    healthCheck();

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Preloader />
      <Header />
      <main id="main">
        <About />
        <Resume />
        <Testimonial />
        {/* <Blog /> */}
        {/* <Services /> */}
        <Contact />
      </main>
      <Footer />
      <BackToTop />
    </>
  );
};

export default HomePage;
