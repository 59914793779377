import axios from "axios";
import { IContactUs } from "../model/request/IContactUs";
import { Constants } from "../../common/Constants";

async function healthCheck() {
  let url = Constants.PROD_URL + "/actuator/health";
  return axios.get(url);
}

function contactUs(contactUs: IContactUs) {
  let url = Constants.PROD_URL + "/api/notification/email/send";
  return axios.post(url, contactUs);
}

const CvBuilderService = {
  contactUs,
  healthCheck,
};

export default CvBuilderService;
