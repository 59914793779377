import React from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import HomePage from "./web/pages/HomePage";
import Redirect from "./admin/components/Redirect";
import { RouteLinks } from "./common/RouteLinks";
import AdminPersonal from "./admin/pages/AdminPersonal";
import AdminEducational from "./admin/pages/AdminEducational";
import AdminProfessional from "./admin/pages/AdminProfessional";
import AdminAchievements from "./admin/pages/AdminAchievements";
import AdminHobbies from "./admin/pages/AdminHobbies";
import AdminHomePage from "./admin/pages/AdminHomePage";
import { Security } from "@okta/okta-react";
import { ProtectedRoute } from "./admin/components/ProtectedRoute";
import OktaLogin from "./admin/components/OktaLogin";
import LoginCallback from "./admin/components/OktaCallback";
import OktaSignup from "./admin/components/OktaSignup";
import oktaAuth from "./config/oktaConfig";

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    console.log("restoreOriginalUri:: " + originalUri);
    navigate(originalUri || "/"); // Redirects after login
  };
  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          {/* Web Route */}
          <Route path={RouteLinks.HOME} element={<HomePage />}></Route>

          {/* Admin Routes */}
          <Route path={RouteLinks.ADMIN} element={<Redirect />}></Route>
          <Route
            path={RouteLinks.ADMIN_SIGNUP}
            element={<OktaSignup />}
          ></Route>
          <Route path={RouteLinks.ADMIN_LOGIN} element={<OktaLogin />}></Route>
          <Route
            path={RouteLinks.ADMIN_LOGIN_CALLBACK}
            element={<LoginCallback />}
          />

          {/* Protected Admin Route */}
          <Route
            path={RouteLinks.ADMIN_HOME}
            element={<ProtectedRoute component={AdminHomePage} />}
          ></Route>
          <Route
            path={RouteLinks.ADMIN_PERSONAL}
            element={<ProtectedRoute component={AdminPersonal} />}
          ></Route>
          <Route
            path={RouteLinks.ADMIN_EDUCATIONAL}
            element={<ProtectedRoute component={AdminEducational} />}
          ></Route>
          <Route
            path={RouteLinks.ADMIN_PROFESSIONAL}
            element={<ProtectedRoute component={AdminProfessional} />}
          ></Route>
          <Route
            path={RouteLinks.ADMIN_ACHIEVEMENTS}
            element={<ProtectedRoute component={AdminAchievements} />}
          ></Route>
          <Route
            path={RouteLinks.ADMIN_HOBBIES}
            element={<ProtectedRoute component={AdminHobbies} />}
          ></Route>
        </Routes>
      </Security>
    </>
  );
}

export default App;
