import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { RouteLinks } from "../../common/RouteLinks";
import { useNavigate } from "react-router-dom";

const OktaLogout: React.FC = () => {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin + RouteLinks.ADMIN_LOGIN,
    });
    // window.location.href = window.location.origin + RouteLinks.ADMIN_LOGIN;
    navigate(RouteLinks.ADMIN_LOGIN);
  };

  return (
    <button className="btn btn-light" onClick={handleLogout}>
      Logout
    </button>
  );
};

export default OktaLogout;
