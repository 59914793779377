import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
import "../assets/css/style.css";
import { disableBack } from "../../common/Utility";
import Header from "../components/AdminHeader";
import Footer from "../components/AdminFooter";
import Languages from "../components/Languages";
import { RouteLinks } from "../../common/RouteLinks";
import PersonalDetails from "../components/PersonalDetails";
import EducationalDetails from "../components/EducationalDetails";
import ProfessionalDetails from "../components/ProfessionalDetails";
import Achievements from "../components/Achievements";
import Hobbies from "../components/Hobbies";
import { useSearchParams } from "react-router-dom";
import CvBuilderAdminService from "../services/CvBuilderAdminService";
import { useOktaAuth } from "@okta/okta-react";

const AdminHomePage: React.FC = () => {
  // const [path] = useSearchParams();
  // console.log("Path:: " + path);
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;

  useEffect(() => {
    const fetchData = async () => {
      console.log(
        "authState?.accessToken?.accessToken:: " +
          authState?.accessToken?.accessToken
      );

      const userInfo = await oktaAuth.getUser();
      console.log("User Info:: " + JSON.stringify(userInfo));
      await CvBuilderAdminService.getResume(accessToken, userInfo?.email).then(
        (response) => {
          console.log("Response data:: " + JSON.stringify(response.data));
        }
      );
    };

    fetchData();
  }, [authState]);

  return (
    <>
      <Header />
      <main id="main">
        <div className="container-fluid">
          Home
          {/* {path === RouteLinks.ADMIN_PERSONAL && <PersonalDetails />}
          {path === RouteLinks.ADMIN_EDUCATIONAL && <EducationalDetails />}
          {path === RouteLinks.ADMIN_PROFESSIONAL && <ProfessionalDetails />}
          {path === RouteLinks.ADMIN_ACHIEVEMENTS && <Achievements />}
          {path === RouteLinks.ADMIN_HOBBIES && <Hobbies />} */}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AdminHomePage;
