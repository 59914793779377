import axios from "axios";
import { Constants } from "../../common/Constants";
import { PersonalProfile } from "../model/PersonalProfile";

async function getResume(
  accessToken: string | undefined,
  email: string | undefined
) {
  return axios.get<PersonalProfile>(
    Constants.PROD_URL + "/secure/cv-builder-app/profile?q=" + email,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
}

const CvBuilderAdminService = {
  getResume,
};

export default CvBuilderAdminService;
