import React from "react";
import { RouteLinks } from "../../common/RouteLinks";
import { useNavigate } from "react-router-dom";

const Redirect: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(RouteLinks.ADMIN_LOGIN);
  }, []);

  return <></>;
};

export default Redirect;
