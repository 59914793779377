import OktaAuth from "@okta/okta-auth-js";
import { RouteLinks } from "../common/RouteLinks";

const issuer =
  process.env.REACT_APP_OKTA_ISSUER + RouteLinks.OKTA_ISSUER_DEFAULT;
const clientId = process.env.REACT_APP_OKTA_CLIENT_ID;

if (!issuer || !clientId) {
  throw new Error(
    "Missing Okta configuration! Ensure OKTA_ISSUER and OKTA_CLIENT_ID are set."
  );
}

const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: clientId,
  redirectUri: window.location.origin + RouteLinks.ADMIN_LOGIN_CALLBACK,
  pkce: true,
  scopes: ["openid", "profile", "email"],
  responseType: ["token", "id_token"],
});

export default oktaAuth;
